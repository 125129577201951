
import { Prop, Watch } from 'vue-property-decorator';
import { Options, Vue } from 'vue-class-component';
import { CompanyClient, GarmentClient, ProductionPhaseConfigurationClient, RawMaterialClient } from '@/services/Services';
import * as OM from '@/Model';

@Options({})
export default class EditModal extends Vue {

    @Prop({
        default: () => new OM.RawMaterialConfigurationVM()
    }) model: OM.RawMaterialConfigurationVM;
    localModel: OM.RawMaterialConfigurationVM = new OM.RawMaterialConfigurationVM();

    garmentIds: OM.TextIdentifier[] = [];
    rawMatIds: OM.TextIdentifier[] = [];
    categories: string[] = [];
    companies: OM.Company[] = [];
    productionPhaseIds: OM.TextIdentifier[] = [];

    newWaterFootprintRow: OM.WaterFootprint = new OM.WaterFootprint();

    @Prop() callback: any;

    created() {
        this.localModel = {...this.model};

        Promise.all([
            GarmentClient.getAllConfigurations(),
            RawMaterialClient.getAllConfigurations(),
            RawMaterialClient.getAllCategories(),
            CompanyClient.getAll(),
            ProductionPhaseConfigurationClient.getAllConfigurations()
        ])
        .then(xs => {
            xs[0].forEach(element => {
                var garment = new OM.TextIdentifier();
                garment.identifier = element.identifier;
                garment.text = element.text;
                this.garmentIds.push(garment);
            });

            xs[1].forEach(element => {
                var newTexId = new OM.TextIdentifier();
                newTexId.identifier = element.identifier;
                newTexId.text = element.materialName;
                this.rawMatIds.push(newTexId);
            });

            this.categories = xs[2];
            this.companies = xs[3];

            var rawMatId = new OM.TextIdentifier();
            rawMatId.identifier = "00000000-0000-0000-0000-000000000000";
            rawMatId.text = "Raw Material";
            this.productionPhaseIds.push(rawMatId);

            xs[4].forEach(element => {
                var phaseId = new OM.TextIdentifier();
                phaseId.identifier = element.identifier;
                phaseId.text = element.productionPhase;
                this.productionPhaseIds.push(phaseId);
            });
        })
    }

    get canSave(){
        return this.localModel.materialName && this.localModel.suggestedGramsPerSquareMeters && this.localModel.cO2PerGrams;
    }

    toggleGarment(garmentId: string) {
        var existingIndex = this.localModel.garmentsUsage.indexOf(garmentId);
        if(existingIndex == -1)
            this.localModel.garmentsUsage.push(garmentId);
        else
            this.localModel.garmentsUsage.splice(existingIndex, 1);
    }
    toggleCompany(companyId: string) {
        var existingIndex = this.localModel.companiesUsage.indexOf(companyId);
        if(existingIndex == -1)
            this.localModel.companiesUsage.push(companyId);
        else
            this.localModel.companiesUsage.splice(existingIndex, 1);
    }

    get remainingPhasesIIds() {
        return this.productionPhaseIds.filter(x => this.localModel.waterFootprints.findIndex(y => y.productionPhase.identifier == x.identifier || (!y.productionPhase.identifier && x.text == "Raw Material")) == -1)
    }

    addWaterFootprintRow() {
        var phaseName = this.productionPhaseIds.filter(x => x.identifier == this.newWaterFootprintRow.productionPhase.identifier)[0];
        this.newWaterFootprintRow.productionPhase.text = phaseName.text;
        this.localModel.waterFootprints.push({...this.newWaterFootprintRow});
        this.newWaterFootprintRow = new OM.WaterFootprint();
    }
    removeWaterFootprintRow(index: number) {
        this.localModel.waterFootprints.splice(index, 1);
    }

    save() {
        if(!this.canSave)
            return;

        this.callback(this.localModel);
    }
    
}
